var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"10","offset-lg":"1","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Thumbnail","vid":"thumbnail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thumbnail Image"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"id":"thumbnail","rows":"6","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleImg},model:{value:(_vm.formData.thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "thumbnail", $$v)},expression:"formData.thumbnail"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Daily Mission","vid":"daily_mission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Daily Mission"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"daily_mission","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.daily_mission),callback:function ($$v) {_vm.$set(_vm.formData, "daily_mission", $$v)},expression:"formData.daily_mission"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Promotion Link","vid":"promotion_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Promotion Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"promotion_link","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.promotion_link),callback:function ($$v) {_vm.$set(_vm.formData, "promotion_link", $$v)},expression:"formData.promotion_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"VPN List","vid":"vpn_list","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" VPN List "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"options":_vm.vpnList,"multiple":true,"id":"vpn_list","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.vpn_list),callback:function ($$v) {_vm.$set(_vm.formData, "vpn_list", $$v)},expression:"formData.vpn_list"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" VPN ON OFF ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.formData.vpn_on),callback:function ($$v) {_vm.$set(_vm.formData, "vpn_on", $$v)},expression:"formData.vpn_on"}},[(_vm.formData.vpn_on)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"}),_c('div',{staticClass:"col text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))]),_vm._v("   "),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-1')}}},[_vm._v("Close")])],1)])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }